import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'Main',
		component: () => import(/* webpackChunkName: "about" */ '../views/Main.vue'),
		children: [
			{
				path: '',
				name: 'Home',
				component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
			},
			{
				path: 'about',
				name: 'About',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
			},
			{
				path: 'campaigns',
				name: 'Campaigns',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/Campaigns.vue')
			},
			{
				path: 'products',
				name: 'Products',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/Products.vue')
			},
			{
				path: 'menucards',
				name: 'MenuCards',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/MenuCards.vue')
			},
			{
				path: 'menucards/:menuCardID',
				name: 'MenuCard',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/MenuCard.vue')
			},
			{
				path: 'menucards/:menuCardID/:productGroupID',
				name: 'ProductGroup',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/ProductGroup.vue')
			},
			{
				path: 'allergies',
				name: 'Allergies',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/Allergies.vue')
			},
			{
				path: 'additives',
				name: 'Additives',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/Additives.vue')
			},
			{
				path: '/additives/:additive',
				name: 'AdditiveVariations',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/AdditiveVariations.vue')
			},
			{
				path: 'welcomescreens',
				name: 'Welcome Screens',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/WelcomeScreens.vue')
			},
			{
				path: 'welcomescreencomponents',
				name: 'Welcome Screen Components',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/WelcomeScreenComponents.vue')
			},
			{
				path: 'contact',
				name: 'Contact',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
			},
			{
				path: 'changelocation/:locationID',
				name: 'Change Location',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/SelectLocation.vue')
			},
			{
				path: 'openinghours',
				name: 'Opening Hours',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/OpeningHours.vue')
			},
			{
				path: 'owner',
				name: 'Location Owner',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/LocationOwner.vue')
			},
			{
				path: 'openinghours/edit/:ohID',
				name: 'Edit Opening Hours',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/EditOpeningHours.vue')
			},
			{
				path: 'analytics',
				name: 'Analytics',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/Analytics.vue')
			},
			{
				path: 'analytics/ranking/:kind',
				name: 'Ranking',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/Ranking.vue')
			},
			{
				path: 'images',
				name: 'Images',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../views/Images.vue')
			}
		]
	},
  	{
		path: '/login',
    	name: 'Login',
		component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  	},
	{
		path: '/signup',
    	name: 'Sign up',
		component: () => import(/* webpackChunkName: "about" */ '../views/SignUp.vue'),
  	},
	{
		path: '/forget-passwort',
    	name: 'Forget Passwort',
		component: () => import(/* webpackChunkName: "about" */ '../views/ForgetPassword.vue'),
  	}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
