<template>
	<router-view/>

	<!--
		<div class="container-fluid">
    <div class="row ">
      <div class="sidebar sidebar-sticky d-none d-sm-block col-sm-4 col-md-3 col-xl-2 ps-0">
        <div class="">
            <SidebarTop class="mt-2 ms-3" />
            <SidebarSelectedLocation class="ms-3" />
            <nav class="navbar ms-3">
                <nav class=" nav flex-column nav-pills">
					<router-link class="nav-link" to="/">Übersicht</router-link>
					<router-link class="nav-link" to="/campaigns">Kampagnen</router-link>
					<router-link class="nav-link" to="/menucards">Speisekarten</router-link>
					<router-link class="nav-link" to="/products">Produkte</router-link>
					<router-link class="nav-link" to="/allergies">Allergene</router-link>
					<router-link class="nav-link" to="/additives">Zusatzstoffe</router-link>
					<router-link class="nav-link" to="/welcomescreens">Welcome Screens</router-link>
					<router-link class="nav-link" to="/openinghours">Öffungszeiten</router-link>
					<router-link class="nav-link" to="/contact">Kontaktdaten</router-link>
					<router-link class="nav-link disabled" to="/images">Bilder</router-link>
					<router-link class="nav-link disabled" to="/category">Kategorie</router-link>
					<router-link class="nav-link" to="/analytics">Analytics</router-link>
                </nav>
            </nav>
        </div>
        <div class="version">
          <SidebarVersion/>
        </div>
      </div>
      <div class="col-sm-8 col-md-9 col-xl-10">
        <main role="main" class="pb-3">
          <router-view/>
        </main>
      </div>
    </div>
  </div>-->
  
</template>

<script>
//import SidebarTop from './components/SidebarTop.vue'
//import SidebarSelectedLocation from './components/SidebarSelectedLocation.vue'
//import SidebarVersion from './components/SidebarVersion.vue'
// import axios from 'axios'

export default {
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  created () {
    /*
    axios.get('baseApiURLlocations').then((res) => {
      //console.log(res)
      if (res.status === 200) {
        this.locations = res.data
        if (this.locations.length > 0) {
          this.selectedLocation = this.locations[0]
        }
        //console.log(this.locations)
      }
    }, (error) => {
      //console.log(error)
    })
    */
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*
  text-align: center;
  */
  background-color: #eaecef;
}

/*
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
@media (max-width: 767.98px) {
  .sidebar {
      top: 5rem;
  }
}

*/

.nav {
  width: 100vw;
}

.navbar {
  padding-top: 0;
   /* Scrollable contents if viewport is shorter than content. */
}

.sidebar {
  /*
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  background: #eaecef;
}

.sidebar-sticky {
  overflow-y: auto;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  align-self: flex-start;
  top: 0;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
.sidebar .nav-link.disabled {
    color: #adb5bc;
}
.nav-link:hover {
    color: rgba(54, 255, 151, 0.4);
    /* color: #007bff; */
}
.sidebar .nav-link:hover {
    background-color: rgba(54, 255, 151, 0.2);
    border-radius: 10px;
    /* color: #007bff; */
}
.sidebar .nav-link.active {
    color: #000;
    background-color: #36FF97;
    border-radius: 10px;
}
.sidebar .nav-link.router-link-exact-active {
    color: #000;
    background-color: #36FF97;
    border-radius: 10px;
}
.sidebar .nav-link {
    font-weight: 500;
    color: #333;
}
.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
    text-align: center;
}
.version {
  align-self: flex-end;
}

  .delete-secondary-btn {
    background-color: #fff;
    color: #e12f41;
    border-radius: 10px;
  }

  .delete-secondary-btn:hover {
    color: #fff;
    background-color: #e12f41;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
    border-radius: 10px;
  }

.clickable-text {
    border-bottom: 3px solid #fff;
  }
  .clickable-text:hover {
    cursor: pointer;
    border-bottom: 3px solid #36FF97;
	color: #000;
  }

 .offcanvas {
    background: fff;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
    border: none;
 }

 .bg-count {
   background: #36FF97;
   color: #000;
 }

 .underlined {
    border-bottom: 1px solid #eaecef;
  }
  .overlined {
    border-top: 1px solid #eaecef;
  }
  .text-btn:hover {
    color: #36FF97;
    cursor: pointer;
  }

  .content-card {
    background-color: #fff;
    border-radius: 10px;
    border: none;
  }

  .content-card:hover {
    background-color: #fff;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
  }

  .title-bar {
		background: rgba( 234, 236, 239, 0.6 );
		box-shadow: 0px 10px 10px -10px rgba(0,0,0,0.2);
		backdrop-filter: blur( 6px );
		-webkit-backdrop-filter: blur( 6px );
	}

</style>
