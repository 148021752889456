import { createApp } from 'vue'
import 'bootstrap/scss/bootstrap.scss'
import ElementPlus from 'element-plus'
//import PrimeVue from 'primevue/config';
import 'element-plus/dist/index.css'
import naive from 'naive-ui'

// import * as d3 from 'd3'

import App from './App.vue'
import router from './router'
import store from './store'

//const app = Vue.createApp({})
//app.use(store).use(router).use(ElementPlus).use(PrimeVue).use(naive).mount('#app')
createApp(App).use(store).use(router).use(ElementPlus).use(naive).mount('#app')
//.use(PrimeVue)
