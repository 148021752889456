import { createStore } from 'vuex'
import axios from 'axios'
import router from '../router'
import { dispatch } from 'd3'

// const baseApiURL = 'http://192.168.188.30:16000/qlm-api/'
//Vue.prototype.$baseApiURL = 'http://localhost:16000/qlm-api/'
//const baseURL = 'http://localhost:16000/'
//const baseApiURL = 'http://localhost:16000/qlm-api/'
//const baseURL = 'http://0.0.0.0:16000/'
//const baseApiURL = 'http://0.0.0.0:16000/qlm-api/'
const baseURL = 'https://qiu.hubnology.com/'
const baseApiURL = 'https://qiu.hubnology.com/qlm-api/'
const LocationModule = {
	namespaced: true,
	state: () => ({
		campaigns: [],
		products: [],
		welcomeScreens: [],
		productGroups: [],
		productGroupProducts: [],
		welcomeScreenComponents: undefined,
		currentFutureOpeningHours: undefined,
		menuCards: [],
		additives: [],
		connectedUsers: 0,
		contactData: undefined,
		openingStatusInfo: undefined,
		top10Products: undefined,
		top10ProductGroups: undefined,
		ranking: undefined,
		selectedMenuCard: undefined,
		selectedProductGroup: undefined
	}),
	getters: {
		getProductByID (state, id) {
			return state.products.find(product => product.id === id)
		},
		contactData (state) {
			return state.contactData
		},
		campaigns (state) {
			return state.campaigns
		},
		ranking (state) {
			return state.ranking
		},
		top10Products (state) {
			return state.top10Products
		},
		top10ProductGroups (state) {
			return state.top10ProductGroups
		},
		activeCampaignsCount (state) {
			return state.campaigns.length
		},
		totalCampaignsCount (state) {
			return state.campaigns.length
		},
		products (state) {
			return state.products
		},
		productsCount (state) {
			return state.products.length
		},
		additives (state) {
			return state.additives
		},
		additivesCount (state) {
			return state.additives.length
		},
		welcomeScreens (state) {
			return state.welcomeScreens
		},
		welcomeScreenComponents (state) {
			return state.welcomeScreenComponents
		},
		currentFutureOpeningHours (state) {
			return state.currentFutureOpeningHours
		},
		menuCards (state) {
			return state.menuCards
		},
		productGroups (state) {
			return state.productGroups
		},
		productGroupProducts (state) {
			return state.productGroupProducts
		},
		menuCardsCount (state) {
			return state.menuCards.length
		},
		selectedMenuCard (state) {
			return state.selectedMenuCard
		},
		selectedProductGroup (state) {
			return state.selectedProductGroup
		},
		connectedUsers (state) {
			return state.connectedUsers
		},
		openingStatusInfo (state) {
			return state.openingStatusInfo
		},
	},
	mutations: {
		setContactData (state, contactData) {
			state.contactData = contactData
		},
		setTop10Products (state, top10Products) {
			state.top10Products = top10Products
		},
		setRanking (state, ranking) {
			state.ranking = ranking
		},
		setTop10ProductGroups (state, top10ProductGroups) {
			state.top10ProductGroups = top10ProductGroups
		},
		setConnectedUsers (state, connectedUsers) {
			state.connectedUsers = connectedUsers
		},
		setOpeningStatusInfo (state, openingStatusInfo) {
			state.openingStatusInfo = openingStatusInfo
		},
		setCampaigns (state, campaigns) {
			state.campaigns = campaigns
		},
		setAdditives (state, additives) {
			state.additives = additives
		},
		setProducts (state, products) {
			state.products = products
		},
		setProductGroups (state, productGroups) {
			state.productGroups = productGroups
		},
		setProductGroupProducts (state, productGroupProducts) {
			state.productGroupProducts = productGroupProducts
		},
		setUpdatedProduct (state, updatedProduct) {
			const index = state.products.findIndex((prod) => prod.id === updatedProduct.id)
			state.products.splice(index, 1, updatedProduct)
		},
		setNewProduct (state, newProduct) {
			state.products.push(newProduct)
		},
		sortProducts (state) {
			state.products = state.products.sort((product1, product2) => product1.name === product2.name ? product1.details >= product2.details : product1.name >= product2.name)
		},
		setWelcomeScreens (state, welcomeScreens) {
			state.welcomeScreens = welcomeScreens
		},
		setCurrentFutureOpeningHours (state, currentFutureOpeningHours) {
			state.currentFutureOpeningHours = currentFutureOpeningHours
		},
		setMenuCards (state, menuCards) {
			state.menuCards = menuCards
		},
		setSelectedMenuCard (state, menuCard) {
			state.selectedMenuCard = menuCard
		},
		setWelcomeScreenComponents (state, components) {
			state.welcomeScreenComponents = components
		},
		setSelectedProductGroup (state, productGroup) {
			state.selectedProductGroup = productGroup
		},
		saveProductGroups (state, payload) {
			var editProduct = state.products.find(prod => prod.id === payload.product.id)
			if (editProduct !== undefined && editProduct !== null) {
				editProduct.groups = payload.groups
			}
		},
		sortMenuCards (state) {
			state.menuCards = state.menuCards.sort((menu1, menu2) => menu1.name >= menu2.name)
		},
		// unfinished
		addProductToSelectedProductGroup (state, productID) {
			if (selectedMenuCard !== undefined && selectedMenuCard !== null && selectedProductGroup !== undefined && selectedProductGroup !== null) {
				state.selectedProductGroup.products = state.selectedProductGroup.products.filter(product => product.id === productID)
			}
		},
		// try without
		removeProductFromSelectedProductGroup (state, productID) {
			if (selectedMenuCard !== undefined && selectedMenuCard !== null && selectedProductGroup !== undefined && selectedProductGroup !== null) {
				state.selectedProductGroup.products = state.selectedProductGroup.products.filter(product => product.id === productID)
			}
		},
		setUpdatedProductGroup (state, updatedProductGroup) {
			const index = state.productGroups.findIndex((group) => group.id === updatedProductGroup.id)
			state.productGroups.splice(index, 1, updatedProductGroup)
		},
		setNewProductGroup (state, newProductGroup) {
			state.productGroups.push(newProductGroup)
		},
		deleteProduct (state, product) {
			state.products = state.products.filter((prod) => prod.id !== product.id)
		},
		deleteProductGroup (state, productGroup) {
			state.productGroups = state.productGroups.filter((group) => group.id !== productGroup.id)
		}
	},
	actions: {
		// Contact data
		loadContactData ({ commit, rootState }) {
			//console.log(`load contact data`)
			//console.log('selected Location', rootState.selectedLocation)
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/contactData`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 200) {
				//console.log(`loaded contact data`, res.data)
				commit('setContactData', res.data)
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},


		// Products
		loadProducts ({ commit, rootState }) {
			//console.log('load products')
			//console.log('selected Location', rootState.selectedLocation)
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/products`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 200) {
					//console.log('loaded products', res.data)
					commit('setProducts', res.data)
					commit('sortProducts')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		saveUpdatedProduct ({ commit, rootState }, updatedProduct) {
			//console.log('save updated product')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.put(`${baseApiURL}locations/${rootState.selectedLocation.id}/products/${updatedProduct.id}`, updatedProduct, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 200) {
					//console.log('saved updated product', res.data)
					commit('setUpdatedProduct', res.data)
					commit('sortProducts')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		saveNewProduct ({ commit, rootState }, newProduct) {
			//console.log('save new product')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/products`, newProduct, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 200) {
					//console.log('saved new product', res.data)
					commit('setNewProduct', res.data)
					commit('sortProducts')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		saveNewProductAllergies ({ commit, rootState }, newProductAllergies) {
			//console.log('save new product')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/allergies`, newProductAllergies, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 200) {
					//console.log('saved new product allergy', res.data)
					commit('setProducts', res.data)
					commit('sortProducts')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		loadProductGroupProducts ({ commit, rootState }) {
			//console.log('load product group products')
			//console.log('selected Location', rootState.selectedLocation)
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/productGroupProducts`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 200) {
				//console.log('loaded product group products', res.data)
				commit('setProductGroupProducts', res.data)
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		addProductImage ({ rootState, dispatch }, newProductImage) {
			//console.log('add new image to product')

			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/products/${newProductImage.productID}/image`, newProductImage.image, { headers: { 'Authorization': `Bearer ${rootState.jwt}`, "Content-Type": "multipart/form-data" } }).then((res) => {
				if (res.status === 201) {
				//console.log('added image to product')
				dispatch('loadProducts')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		addProductAllergies ({ rootState, dispatch }, newAllergyProducts) {
			//console.log('add new allergies to product')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/products/${newAllergyProducts.productID}/allergies`, newAllergyProducts.allergies, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
				//console.log('added new allergies to product')
				dispatch('loadProducts')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		deleteProduct ({ commit, rootState }, productToDelete) {
			//console.log('delete product', productToDelete)
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/products/${productToDelete.id}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 204) {
					//console.log('deleted product')
					commit('deleteProduct', productToDelete)
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},

		// Menu cards
		findMenuCardByID (state, id) {
			return state.menuCards.find(card => card.id === id)
		},
		loadMenuCards ({ commit, rootState }) {
			//console.log('load menu cards')
			//console.log('selected Location', rootState.selectedLocation)
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/menucards`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 200) {
					//console.log('loaded menu cards', res.data)
					commit('setMenuCards', res.data)
					commit('sortMenuCards')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		saveMenuCard ({ rootState, dispatch }, editedMenuCard) {
			//console.log('save edited menu card')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.put(`${baseApiURL}locations/${rootState.selectedLocation.id}/menuCards/${editedMenuCard.id}`, editedMenuCard, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
					//console.log('saved edited menu card')
					dispatch('loadMenuCards')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		async loadMenuCard ({ commit, state, rootState }, menuCardID) {
			//console.log('load menu card', menuCardID)
			//console.log('selected Location', rootState.selectedLocation)
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				const response = await axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/menucards/${menuCardID}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } })
				//console.log('response', response)
				if (response.status === 200) {
					//console.log('loaded menu card', response.data)
					commit('setSelectedMenuCard', response.data)
					return response.data
				}
			}
			return undefined
		},
		async saveNewMenuCard ({ rootState, dispatch }, newMenuCard) {
				//console.log('save new menu card')
				if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
					const response = await axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/menuCards`, newMenuCard, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } })
					if (response.status === 201) {
						dispatch('loadMenuCards')
					}
				}
		},
		async addMenuCardVisibility ({ rootState, dispatch }, visibility) {
			//console.log('add visibility to menu card')
			//console.log('visibility', visibility)
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				const response = await axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/menuCards/${visibility.id}/visibility`, visibility.visibility, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } })
				if (response.status === 201) {
					//console.log('added visibility to menu card')
					dispatch('loadMenuCards')
					//dispatch('loadMenuCard', visibility.id)
				}
			}
		},
		async removeMenuCardVisibility ({ rootState, dispatch }, visibility) {
			//console.log('remove visibility from menu card')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				const response = await axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/menuCards/${visibility.id}/visibility/${visibility.day}/${visibility.from}/${visibility.until}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } })
				if (response.status === 204) {
					//console.log('removed visibility from menu card')
					dispatch('loadMenuCards')
					//dispatch('loadMenuCard', visibility.id)
				}
			}
		},
		deleteMenuCard ({ rootState, dispatch }, menuCardID) {
			//console.log('delete menu card')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/menuCards/${menuCardID}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 204) {
					//console.log('deleted menu card')
					dispatch('loadMenuCards')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},

		// Analytics
		loadRanking ({ commit, rootState }, kind) {
		//console.log(`load ranking ${kind}`)
		//console.log('selected Location', rootState.selectedLocation)
		if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/analytics/ranking/${kind}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
			if (res.status === 200) {
				//console.log(`loaded ranking ${kind}`, res.data)
				commit('setRanking', res.data)
			}
			}, (error) => {
			//console.log(error)
			})
		}
		},
		loadTop10Products ({ commit, rootState }) {
		//console.log('load top10 Products')
		//console.log('selected Location', rootState.selectedLocation)
		if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/analytics/ranking/products/top10`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
			if (res.status === 200) {
				//console.log('loaded top10 Products', res.data)
				commit('setTop10Products', res.data)
			}
			}, (error) => {
			//console.log(error)
			})
		}
		},
		loadTop10ProductGroups ({ commit, rootState }) {
		//console.log('load top10 ProductGroups')
		//console.log('selected Location', rootState.selectedLocation)
		if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/analytics/ranking/productGroups/top10`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
			if (res.status === 200) {
				//console.log('loaded top10 ProductGroups', res.data)
				commit('setTop10ProductGroups', res.data)
			}
			}, (error) => {
			//console.log(error)
			})
		}
		},

		// Live data
		loadConnectedUsers ({ commit, rootState }) {
		//console.log('load connected users')
		//console.log('selected Location', rootState.selectedLocation)
		if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/stats/currentlyConnectedCount`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
			if (res.status === 200) {
				//console.log('loaded connected users', res.data)
				commit('setConnectedUsers', res.data)
			}
			}, (error) => {
			//console.log(error)
			})
		}
		},
		loadOpeningStatusInfo ({ commit, rootState }) {
		//console.log('load opening status info')
		//console.log('selected Location', rootState.selectedLocation)
		if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/stats/openingStatusInformation`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
			if (res.status === 200) {
				//console.log('loaded opening status info', res.data)
				commit('setOpeningStatusInfo', res.data)
			}
			}, (error) => {
			//console.log(error)
			})
		}
		},

		// Campaigns
		loadCampaigns ({ commit, rootState }) {
		//console.log('load campaigns')
		//console.log('selected Location', rootState.selectedLocation)
		if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/campaigns`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
			if (res.status === 200) {
				//console.log('loaded campaigns', res.data)
				commit('setCampaigns', res.data)
			}
			}, (error) => {
			//console.log(error)
			})
		}
		},
		deleteCampaign ({ commit, state, rootState }, campaign) {
			//console.log('delete campaign', campaign)
		},

		// Additives
		loadAdditives ({ commit, rootState }) {
		//console.log('load additives')
		//console.log('selected Location', rootState.selectedLocation)
		if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined) {
			axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/additives`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
			if (res.status === 200) {
				//console.log('loaded additives', res.data)
				commit('setAdditives', res.data)
			}
			}, (error) => {
			//console.log(error)
			})
		}
		},

		// Product Groups
		addProductGroupImage ({ rootState, dispatch }, newProductGroupImage) {
			//console.log('add new image to product group')

			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/productGroups/${newProductGroupImage.productGroupID}/image`, newProductGroupImage.image, { headers: { 'Authorization': `Bearer ${rootState.jwt}`, "Content-Type": "multipart/form-data" } }).then((res) => {
				if (res.status === 201) {
				//console.log('added image to product group')
				dispatch('loadMenuCards')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		deleteProductGroup ({ commit, rootState }, productGroupToDelete) {
			//console.log('delete product group', productGroupToDelete)
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/productGroups/${productGroupToDelete.id}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 200) {
					//console.log('deleted product group')
					commit('setProductGroups', res.data)
					commit('sortProductGroups')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		saveNewProductGroup ({ dispatch, state, rootState }, newProductGroup) {
			//console.log('save new product group')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined && state.selectedMenuCard !== null && state.selectedMenuCard !== undefined) {
				axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/menuCards/${state.selectedMenuCard.id}/productgroups`, newProductGroup, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
					//console.log('saved new product group')
					dispatch('loadMenuCard', state.selectedMenuCard.id)
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		removeProductFromProductGroup ({ commit, rootState }, productToRemove) {
			//console.log('remove group from product group', productToRemove)
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/productGroups/${productToRemove.parentGroupID}/product/${productToRemove.productID}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 200) {
					//console.log('removed group from product group')
					commit('setProductGroups', res.data)
					commit('sortProductGroups')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		loadProductGroups ({ commit, rootState }) {
			//console.log('load product groups')
			//console.log('selected Location', rootState.selectedLocation)
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/productGroups`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 200) {
				//console.log('loaded product groups', res.data)
				commit('setProductGroups', res.data)
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		loadProductGroup ({ commit, state, rootState }, productGroupID) {
			//console.log('load product group', productGroupID)
			//console.log('selected menu card', state.selectedMenuCard)
			//console.log('selected Location', rootState.selectedLocation)
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/menucards/${state.selectedMenuCard.id}/productGroups/${productGroupID}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 200) {
					//console.log('loaded product group', res.data)
					commit('setSelectedProductGroup', res.data)
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		addProductsToProductGroup ({ rootState, dispatch }, newProducts) {
			//console.log('add new products to product group')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined && selectedMenuCard !== undefined && selectedMenuCard !== null) {
			axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/menuCards/${selectedMenuCard.id}/productGroups/${newProducts.productGroupID}/products`, newProducts.productIDs, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
				//console.log('added products to product group')
				dispatch('loadMenuCards')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		removeProductFromProductGroup ({ rootState, dispatch }, productToRemove) {
			//console.log('remove product from product group')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/menuCards/${selectedMenuCard.id}/productGroups/${productToRemove.productGroupID}/products/${productToRemove.productID}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 204) {
				//console.log('removed product from product group')
				dispatch('loadMenuCards')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},

		// Location Images
		addLocationImage ({ rootState, dispatch }, newLocationImage) {
			//console.log('add new image to location')

			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/images`, newLocationImage, { headers: { 'Authorization': `Bearer ${rootState.jwt}`, "Content-Type": "multipart/form-data" } }).then((res) => {
				if (res.status === 201) {
				//console.log('added image to location')
				rootState.dispatch('loadLocations')
				//dispatch('loadProducts')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},

		// Allergies
		addProductsToAllergy ({ rootState, dispatch }, newAllergyProducts) {
			//console.log('add new products to allergy')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/allergies/${newAllergyProducts.allergy}/products`, newAllergyProducts.productIDs, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
					//console.log('added products to allergy')
					dispatch('loadProducts')
				}
				}, (error) => {
				//console.log(error)
				})
			}
			},
		deleteProductAllergy ({ rootState, dispatch }, productAllergyToRemove) {
		//console.log('delete product allergy')
		if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/allergies/${productAllergyToRemove.allergy}/${productAllergyToRemove.productID}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
			if (res.status === 204) {
				//console.log('deleted product allergy')
				dispatch('loadProducts')
			}
			}, (error) => {
			//console.log(error)
			})
		}
		},

		// Welcome Screens
		loadWelcomeScreens ({ commit, rootState }) {
			//console.log('load welcome Screens')
			//console.log('selected Location', rootState.selectedLocation)
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreens`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 200) {
					//console.log('loaded welcomeScreens', res.data)
					commit('setWelcomeScreens', res.data)
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		saveNewWelcomeScreen ({ dispatch, rootState }, newWS) {
			//console.log('save new ws')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreens`, newWS, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
					//console.log('saved new ws')
					dispatch('loadWelcomeScreens')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		saveEditedWelcomeScreen ({ dispatch, rootState }, editedWS) {
			//console.log('save edited ws')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.put(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreens/${editedWS.id}`, editedWS.ws, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
					//console.log('saved edited ws')
					dispatch('loadWelcomeScreens')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		deleteWelcomeScreen ({ dispatch, rootState }, wsID) {
			//console.log('delete ws')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreens/${wsID}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 204) {
					//console.log('deleted ws')
					dispatch('loadWelcomeScreens')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		saveNewWSComponents ({ dispatch, rootState }, newComponents) {
			//console.log('save new ws components components')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreens/${newComponents.wsID}/components`, newComponents.newComponents, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
					//console.log('saved new ws components')
					dispatch('loadWelcomeScreens')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		deleteWSComponent ({ dispatch, rootState }, component) {
			//console.log('delete ws component')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreens/${component.wsID}/components/${component.componentID}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 204) {
					//console.log('deleted ws component')
					dispatch('loadWelcomeScreens')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		saveNewTextType ({ dispatch, rootState }, newTextType) {
			//console.log('update ws component text type')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.put(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreens/${newTextType.wsID}/components/${newTextType.componentID}/newTextType`, newTextType.textType, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
					//console.log('updated ws component text type')
					dispatch('loadWelcomeScreens')
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		saveNewWSOrderType ({ dispatch, rootState }, newWSOrder) {
			//console.log('update ws components order')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.put(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreens/${newWSOrder.wsID}/components/order`, newWSOrder.newOrder, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
					//console.log('updated ws components order')
					dispatch('loadWelcomeScreens')
				}
				}, (error) => {
					//console.log(error)
				})
			}
		},
		async addWSVisibility ({ rootState, dispatch }, visibility) {
			//console.log('add visibility to welcome screen')
			//console.log('visibility', visibility)
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				const response = await axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreens/${visibility.id}/visibility`, visibility.visibility, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } })
				if (response.status === 201) {
					//console.log('added visibility to welcome screen')
					dispatch('loadWelcomeScreens')
				}
			}
		},
		async removeWSVisibility ({ rootState, dispatch }, visibility) {
			//console.log('remove visibility from welcome screen')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				const response = await axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreens/${visibility.id}/visibility/${visibility.day}/${visibility.from}/${visibility.until}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } })
				if (response.status === 204) {
					//console.log('removed visibility from welcome screen')
					dispatch('loadWelcomeScreens')
				}
			}
		},

		// Welcome Screen Components
		loadWelcomeScreenComponents ({ commit, rootState }) {
		//console.log('load welcome Screens components')
		//console.log('selected Location', rootState.selectedLocation)
		if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreenComponents`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
			if (res.status === 200) {
				//console.log('loaded welcome Screen components', res.data)
				commit('setWelcomeScreenComponents', res.data)
			}
			}, (error) => {
			//console.log(error)
			})
		}
		},
		saveNewTextComponent ({ dispatch, state, rootState }, newComponent) {
			//console.log('save new text component')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined) {
			axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreenComponents/text`, newComponent, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
				//console.log('saved new text component')
				dispatch('loadWelcomeScreenComponents')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		saveNewImagesComponent ({ dispatch, state, rootState }, newComponent) {
			//console.log('save new images component')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined) {
			axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreenComponents/images`, newComponent, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
				//console.log('saved new images component')
				dispatch('loadWelcomeScreenComponents')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		saveNewMenuCardsComponent ({ dispatch, state, rootState }, newComponent) {
			//console.log('save new menu cards component')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined) {
			axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreenComponents/menuCards`, newComponent, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
				//console.log('saved new menu cards component')
				dispatch('loadWelcomeScreenComponents')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		saveNewProductGroupsComponent ({ dispatch, state, rootState }, newComponent) {
			//console.log('save new product groups component')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined) {
			axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreenComponents/productGroups`, newComponent, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
				//console.log('saved new product groups component')
				dispatch('loadWelcomeScreenComponents')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		saveNewProductGroupProductsComponent ({ dispatch, state, rootState }, newComponent) {
			//console.log('save new product group products component')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined) {
			axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreenComponents/productGroupProducts`, newComponent, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 201) {
				//console.log('saved new product group products component')
				dispatch('loadWelcomeScreenComponents')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		deleteTextComponent ({ dispatch, state, rootState }, componentID) {
			//console.log('delete text component')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined) {
			axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreenComponents/text/${componentID}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 204) {
				//console.log('deleted text component')
				dispatch('loadWelcomeScreenComponents')
				dispatch('loadWelcomeScreens')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		deleteImagesComponent ({ dispatch, state, rootState }, componentID) {
			//console.log('delete images component')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined) {
			axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreenComponents/images/${componentID}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 204) {
				//console.log('deleted images component')
				dispatch('loadWelcomeScreenComponents')
				dispatch('loadWelcomeScreens')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		deleteMenuCardsComponent ({ dispatch, state, rootState }, componentID) {
			//console.log('delete menu cards component')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined) {
			axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreenComponents/menuCards/${componentID}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 204) {
				//console.log('deleted menu cards component')
				dispatch('loadWelcomeScreenComponents')
				dispatch('loadWelcomeScreens')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		deleteProductGroupsComponent ({ dispatch, state, rootState }, componentID) {
			//console.log('delete product groups component')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined) {
			axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreenComponents/productGroups/${componentID}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 204) {
				//console.log('deleted product groups component')
				dispatch('loadWelcomeScreenComponents')
				dispatch('loadWelcomeScreens')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},
		deleteProductsComponent ({ dispatch, state, rootState }, componentID) {
			//console.log('delete products component')
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined) {
			axios.delete(`${baseApiURL}locations/${rootState.selectedLocation.id}/welcomeScreenComponents/products/${componentID}`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
				if (res.status === 204) {
				//console.log('deleted text products')
				dispatch('loadWelcomeScreenComponents')
				dispatch('loadWelcomeScreens')
				}
			}, (error) => {
				//console.log(error)
			})
			}
		},

		// Opening Hours
		loadCurrentFutureOpeningHours ({ commit, rootState }) {
		//console.log('load current and future opening hours')
		//console.log('selected Location', rootState.selectedLocation)
		if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
			axios.get(`${baseApiURL}locations/${rootState.selectedLocation.id}/openingHours`, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
			if (res.status === 200) {
				//console.log('loaded current and future opening hours', res.data)
				commit('setCurrentFutureOpeningHours', res.data)
			}
			}, (error) => {
			//console.log(error)
			})
		}
		},
		addFutureOpeningHours ({ dispatch, rootState }, from) {
			//console.log('add new future opening hours')
			//console.log('selected Location', rootState.selectedLocation)
			if (rootState.selectedLocation !== null && rootState.selectedLocation !== undefined ) {
				axios.post(`${baseApiURL}locations/${rootState.selectedLocation.id}/openingHours`, { from: from, locationID: rootState.selectedLocation.id }, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } }).then((res) => {
					if (res.status === 201) {
						//console.log('added new future opening hours')
						dispatch('loadCurrentFutureOpeningHours')
					}
				}, (error) => {
					//console.log(error)
				})
			}
		},

		// Images
		async loadImage ({ rootState, dispatch }, imageURL) {
			//console.log('load Image', imageURL)
			const response = await fetch(imageURL, { headers: { 'Authorization': `Bearer ${rootState.jwt}` } })
			//console.log('response', response)
			if (response.ok) {
				const blob = await response.blob()
				//console.log('blob', blob)
				const localImageURL = URL.createObjectURL(blob)
				//console.log('localImageURL', localImageURL)
				return localImageURL
			}
			return undefined
		},
	}
}

export default createStore({
	state: {
		jwt: null,
		allergies: [],
		locations: [],
		selectedLocation: null,
		locationOwner: null
	},
	getters: {
		allergies (state) {
			return state.allergies
		},
		selectedLocation (state) {
			return state.selectedLocation
		},
		locationOwner (state) {
			return state.locationOwner
		},
		locationsCount (state) {
			return state.locations.length
		},
		images (state) {
			if (state.selectedLocation !== undefined && state.selectedLocation !== null && state.selectedLocation.images !== undefined && state.selectedLocation.images !== null) {
				return state.selectedLocation.images
			}
			return []
		},
	},
	mutations: {
		setJWT (state, jwt) {
			state.jwt = jwt
			//console.log('set jwt', state.jwt)
		},
		setAllergies (state, allergies) {
			state.allergies = allergies
			//console.log('loaded allergies', state.allergies)
		},
		sortAllergies (state) {
			state.allergies = state.allergies.sort((allergy1, allergy2) => allergy1.text >= allergy2.text)
		},
		setLocations (state, locations) {
			state.locations = locations
			//console.log('loaded locations', state.locations)
		},
		setLocationOwner (state, locationOwner) {
			state.locationOwner = locationOwner
			//console.log('loaded location Owner', state.locationOwner)
		},
		setLocation (state, location) {
			state.selectedLocation = location
			//console.log('selected location', state.selectedLocation)
		}
	},
	actions: {
		loadAllergies ({ commit, state, dispatch }) {
			axios.get(`${baseApiURL}allergies`, { headers: { 'Authorization': `Bearer ${state.jwt}` } }).then((res) => {
				if (res.status === 200) {
				commit('setAllergies', res.data)
				commit('sortAllergies')
				}
			}, (error) => {
				//console.log(error)
			})
		},
		loadLocations ({ commit, state, dispatch }) {
			if (state.locationOwner !== null) {
				axios.get(`${baseApiURL}locations`, { headers: { 'Authorization': `Bearer ${state.jwt}` } }).then((res) => {
				if (res.status === 200) {
					commit('setLocations', res.data)
					if (state.locations.length > 0) {
					dispatch('changeLocation', state.locations[0])
					}
				}
				}, (error) => {
				//console.log(error)
				})
			}
		},
		loadLocationOwner ({ commit, state, dispatch }) {
			//console.log('Load location owner')
			axios.get(`${baseApiURL}locationOwner`, { headers: { 'Authorization': `Bearer ${state.jwt}` } }).then((res) => {
				//console.log(res)
				if (res.status === 200) {
				commit('setLocationOwner', res.data)
				dispatch('loadLocations')
				}
			}, (error) => {
				//console.log(error)
			})
		},
		login ({ commit, dispatch }, loginInfo) {
			console.log('Login')
			axios.post(`${baseURL}login`, {}, { headers: { 'Authorization': `Basic ${loginInfo}` } }).then((res) => {
				console.log(res)
				if (res.status === 200) {
					commit('setJWT', res.data.token)
					dispatch('loadLocationOwner')
					// dispatch('loadLocations')
				}
			}, (error) => {
				console.log(error)
			})
		},
		changeLocation ({ commit, dispatch }, location) {
			commit('setLocation', location)
			dispatch('locationModule/loadContactData')
			dispatch('locationModule/loadCampaigns')
			dispatch('locationModule/loadProducts')
			dispatch('locationModule/loadProductGroups')
			dispatch('locationModule/loadProductGroupProducts')
			dispatch('locationModule/loadAdditives')
			dispatch('locationModule/loadWelcomeScreens')
			dispatch('locationModule/loadWelcomeScreenComponents')
			dispatch('locationModule/loadCurrentFutureOpeningHours')
			dispatch('locationModule/loadMenuCards')
			dispatch('locationModule/loadConnectedUsers')
			dispatch('locationModule/loadOpeningStatusInfo')
			dispatch('locationModule/loadTop10Products')
			dispatch('locationModule/loadTop10ProductGroups')
			router.push('/')
		}
	},
	modules: {
		locationModule: LocationModule
	}
})
